exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-all-articles-jsx": () => import("./../../../src/pages/all-articles.jsx" /* webpackChunkName: "component---src-pages-all-articles-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-becoming-a-patient-jsx": () => import("./../../../src/pages/becoming-a-patient.jsx" /* webpackChunkName: "component---src-pages-becoming-a-patient-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-educational-videos-jsx": () => import("./../../../src/pages/educational-videos.jsx" /* webpackChunkName: "component---src-pages-educational-videos-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ivitamin-bar-jsx": () => import("./../../../src/pages/ivitamin-bar.jsx" /* webpackChunkName: "component---src-pages-ivitamin-bar-jsx" */),
  "component---src-pages-meet-the-medical-team-jsx": () => import("./../../../src/pages/meet-the-medical-team.jsx" /* webpackChunkName: "component---src-pages-meet-the-medical-team-jsx" */),
  "component---src-pages-patient-stories-jsx": () => import("./../../../src/pages/patient-stories.jsx" /* webpackChunkName: "component---src-pages-patient-stories-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-tour-jsx": () => import("./../../../src/pages/tour.jsx" /* webpackChunkName: "component---src-pages-tour-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-general-page-jsx": () => import("./../../../src/templates/general-page.jsx" /* webpackChunkName: "component---src-templates-general-page-jsx" */),
  "component---src-templates-iv-drip-menu-jsx": () => import("./../../../src/templates/iv-drip-menu.jsx" /* webpackChunkName: "component---src-templates-iv-drip-menu-jsx" */),
  "component---src-templates-iv-vitamins-jsx": () => import("./../../../src/templates/iv-vitamins.jsx" /* webpackChunkName: "component---src-templates-iv-vitamins-jsx" */),
  "component---src-templates-treatment-jsx": () => import("./../../../src/templates/treatment.jsx" /* webpackChunkName: "component---src-templates-treatment-jsx" */)
}

